export const projects = [
    {
        id : 1,
        image : "./images/QuranAcademie.png",
        image_alt : "project-QuranAcademie",
        link : "https://quranakademie.com/",
        project_technologies : "Wordpress, PHP, Javascript, Jquery, CSS3, Bootstrap, Ajax, SQL, MySQL",
        project_name_english : "E-learning platform",
        project_name_french : "Plateforme d'apprentissage en ligne",
        project_name_arabic : "منصة للتعلم عن بعد",
        project_description_english : "Main features",
        project_description_english_list1 : "Online courses.",
        project_description_english_list2 : "Comprehensive student progress tracking.",
        project_description_english_list3 : "Real-time attendance monitoring.",
        project_description_english_list4 : "Monthly reports to students and parents.",
        project_description_english_list5 : "Stripe payment method implementation.",
        project_description_french : "Fonctionnalités principales",
        project_description_french_list1 : "Cours en ligne.",
        project_description_french_list2 : "Suivi complet de la progression des élèves.",
        project_description_french_list3 : "Surveillance en temps réel de la présence.",
        project_description_french_list4 : "Rapports mensuels aux étudiants et aux parents.",
        project_description_french_list5 : "Intégration de Stripe pour les paiements.",
        project_description_arabic : "الميزات الرئيسية",
        project_description_arabic_list1 : "دورات عبر الإنترنت.",
        project_description_arabic_list2 : "تتبع شامل لتقدم الطلاب.",
        project_description_arabic_list3 : "مراقبة الحضور في الوقت الحقيقي.",
        project_description_arabic_list4 : "تقارير شهرية للطلاب وأولياء الأمور.",
        project_description_arabic_list5 : "تنفيذ طريقة الدفع باستخدام Stripe.",
    },
    {
        id : 2,
        image : "./images/MauriGO.png",
        image_alt : "project-Maurigo",
        link : "https://maurigo.com/",
        project_technologies : "PHP, Javascript, Jquery, CSS3, Bootstrap, Ajax, SQL, MySQL",
        project_name_english : "Reservation management app",
        project_name_french : "Application pour gestion des réservations",
        project_name_arabic : "تطبيق إدارة الحجوزات",
        project_description_english : "Main features",
        project_description_english_list1 : "Stripe payment method implementation.",
        project_description_english_list2 : "User account management.",
        project_description_english_list3 : "Reservations management.",
        project_description_english_list4 : "Instant generation of invoices for clients.",
        project_description_english_list5 : "Admin page for reservation management.",
        project_description_french : "Fonctionnalités principales",
        project_description_french_list1 : "Intégration de Stripe pour les paiements.",
        project_description_french_list2 : "Gestion des comptes utilisateurs.",
        project_description_french_list3 : "Gestion des réservations.",
        project_description_french_list4 : "génération des factures pour les clients.",
        project_description_french_list5 : "Page d'admin pour la gestion des réservations.",
        project_description_arabic : "الميزات الرئيسية",
        project_description_arabic_list1 : "تنفيذ طريقة الدفع باستخدام Stripe.",
        project_description_arabic_list2 : "إدارة حسابات المستخدمين.",
        project_description_arabic_list3 : "إدارة الحجوزات.",
        project_description_arabic_list4 : "إصدار فواتير فورية للعملاء.",
        project_description_arabic_list5 : "صفحة الإدارة لإدارة الحجوزات.",
    },
    {
        id : 3,
        image : "./images/englishandtravelagency.png",
        image_alt : "project-Educational",
        link : "https://www.englishandtravelagency.com/",
        project_technologies : "PHP, Javascript, Jquery, CSS3, Bootstrap, Ajax, SQL, MySQL",
        project_name_english : "Business website for educational services",
        project_name_french : "Site web professionnel pour des services éducatifs",
        project_name_arabic : "موقع ويب تجاري لخدمات التعليم",
        project_description_english : "Main features",
        project_description_english_list1 : "Multilingue website.",
        project_description_english_list2 : "Services rating.",
        project_description_english_list3 : "Blogs and comments writing.",
        project_description_english_list4 : "Admin page for blog management.",
        project_description_english_list5 : "Newsletter subscription.",
        project_description_french : "Fonctionnalités principales",
        project_description_french_list1 : "Site web multilingue.",
        project_description_french_list2 : "Évaluations des services.",
        project_description_french_list3 : "Rédaction de blogs et de commentaires.",
        project_description_french_list4 : "Page d'administration pour la gestion du blog.",
        project_description_french_list5 : "Abonnement à la newsletter.",
        project_description_arabic : "موقع ويب متعدد اللغات",
        project_description_arabic_list1 : "تصميم متجاوب.",
        project_description_arabic_list2 : "تقييم الخدمات.",
        project_description_arabic_list3 : "كتابة مدونات وتعليقات.",
        project_description_arabic_list4 : "صفحة الإدارة لإدارة المدونة.",
        project_description_arabic_list5 : "الاشتراك في النشرة الإخبارية.",
    },
    {
        id : 4,
        image : "./images/school_management.png",
        image_alt : "project-school-management",
        link : "",
        project_technologies : "PHP, Javascript, Jquery, CSS3, Bootstrap, Ajax, SQL, MySQL",
        project_name_english : "School management app",
        project_name_french : "Application de gestion d'école",
        project_name_arabic : "تطبيق إدارة مدرسة",
        project_description_english : "Main features",
        project_description_english_list1 : "Student enrollment.",
        project_description_english_list2 : "Attendance tracking.",
        project_description_english_list3 : "Grade management.",
        project_description_english_list4 : "Document issuance.",
        project_description_english_list5 : "Classroom reservations.",
        project_description_french : "Fonctionnalités principales",
        project_description_french_list1 : "Inscription des élèves.",
        project_description_french_list2 : "Suivi de l'absence.",
        project_description_french_list3 : "Gestion des notes.",
        project_description_french_list4 : "Delivrance de documents.",
        project_description_french_list5 : "Réservations de salles de classe.",
        project_description_arabic : "الميزات الرئيسية",
        project_description_arabic_list1 : "تسجيل الطلاب.",
        project_description_arabic_list2 : "تتبع الغياب",
        project_description_arabic_list3 : "إدارة النقط.",
        project_description_arabic_list4 : "إصدار الوثائق.",
        project_description_arabic_list5 : "حجوزات قاعات الدراسة.",
    },
]