export const arabic = {
    //languages
    english : "الإنجليزية",
    french : "الفرنسية",
    arabic : "العربية",
    //navbar
    loading : "جاري التحميل",
    home : "الرئيسية",
    about : "عن أيمن اشنايف",
    services : "الخدمات",
    projects : "المشاريع",
    contact_nav : "تواصل معي",
    //banner
    text_banner1 : "مرحبًا بكم في موقع Aymane Web-Dev \n حيث تترجم الأحلام الرقمية إلى واقع",
    text_banner2 : "حرروا إمكانياتكم على الإنترنت\n مع مطور ويب متخصص",
    text_banner3 : "هل أنتم مستعدون لتعزيز وجودكم على الويب؟\n لنقم ببناء شيء استثنائي معًا.",
    button_banner : "اختارو خدمتكم",
    // modal
    service_title : "الخدمة",
    service_select : "المرجو اختيار خدمتكم",
    service_select_text : "هل يمكنك من فضلك إدخال عنوان بريد إلكتروني صالح حتى تتمكن من المضي إلى الصفحة التالية",
    service_contact_first_name : "الاسم الشخصي",
    service_plugin_maintenance : "هل يمكنكم تقديم تفاصيل متطلباتكم أدناه؟ سيتيح لي ذلك إرسال تقدير أدق لكم",
    service_textarea_placehoder : "من فضلك، قدّم تفاصيل متطلباتك",
    send : "أرسل عرض السعر",
    close : "إغلاق",
    next_text : "للانتقال إلى الصفحة التالية، يرجى اختيار خدمة وإدخال عنوان بريد إلكتروني صالح.",
    //conatct form
    service_contact_last_name : "الاسم العائلي",
    service_contact_phone : "رقم الهاتف",
    service_contact_email : "البريد الإلكتروني",
    service_contact_first_name_placeholder : "اسمكم الشخصي",
    service_contact_last_name_placeholder : "اسمكم العائلي",
    service_contact_phone_placeholder : "رقم هاتفكم",
    service_contact_email_placeholder : "بريدكم الإكتروني",
    optionnal : "اختياري",
    not_optionnal : "إجباري",
    //services
    ecommerce_service : "خدمة التجارة الإلكترونية",
    ecommerce_select : "الرجاء اختيار خدمتك الإلكترونية",
    ecommerce_delivery : "الدفع عند الاستلام فقط",
    ecommerce_delivery_crediCard : "الدفع بواسطة بطاقة الائتمان والدفع عند الاستلام",
    page_add_title : "إضافة الصفحات",
    multiling_page : "صفحة متعددة اللغات",
    multiling_website : "موقع متعدد اللغات",
    lang_website : "لغة",
    lang_websites : "لغات",
    functionlities_add : "إضافة الخاصيات",
    functionlities_select : "المرجو اختيار خاصية",
    dollar : "دولار",
    responsive_service : "تصميم متجاوب",
    newsLetter_service : "الاشتراك في النشرة الإخبارية (News Letter)",
    seo_service : "تحسين محركات البحث (SEO)",
    admin_service : "صفحة الإدارة (صفحة لوحة المتابعة، إضافة، تحديث، حذف عناصر الموقع)",
    other_service : "تعليق",
    other_discuss : "اكتب تعليقًا أو أضف وظائف أخرى",
    error : "حدث خطأ",
    error_language : "جميع حقول اللغات إجباريين",
    //service price
    initial_price : "السعر الأولي",
    initial_price_text : "السعر الأولي يشمل الصفحة الرئيسية، صفحة من نحن، نموذج التواصل",
    initial_price_text_application : "، وجميع وظائف تطبيق الويب",
    initial_price_text_blog1 : "السعر الأولي يتضمن الصفحة الرئيسية، صفحة من نحن، صفحة المدونة، نموذج التواصل، صفحة الإدارة،",
    initial_price_text_blog2 : "وجميع وظائف موقع الويب للمدونة",
    initial_price_text_ecommerce1 : "السعر الأولي يتضمن الصفحة الرئيسية، صفحة من نحن، تصميم متجاوب، نموذج التواصل، نظام تسجيل الدخول للمستخدمين،",
    initial_price_text_ecommerce2 : "صفحة الدفع، صفحة الإدارة، وجميع وظائف موقع التجارة الإلكترونية",
    initial_price_text_educational1 : "السعر الأولي يتضمن الصفحة الرئيسية، صفحة من نحن، نموذج الاتصال، نظام تسجيل الدخول للمستخدمين،",
    initial_price_text_educational2 : "صفحة تنفيذ الدفع، صفحة الإدارة، وجميع وظائف الموقع التعليمي",
    total_price : "السعر الإجمالي",
    total_price_text : "السعر الإجمالي لا يشمل الضرائب",
    total_price_other : "سيتم إضافة سعر الخدمات الأخرى وإرساله إليكم لاحقًا",
    page_add_numbers_title : "صفحات إضافية",
    page : "صفحة",
    pages : "صفحات",
    functionalities : "الخاصيات",
    //about page
    about_header : "عن أيمن اشنايف",
    about_text : "مرحبًا، اسمي أيمن اشنايف، وأنا متحمس لتحويل أفكاركم الرقمية إلى واقع. وذلك بتركيز وجهد عالٍ لإنشاء مواقع وتطبيقات جوال مذهلة وديناميكية، لدي خبرة مثبتة تبلغ ثلاث سنوات في عالم تطوير الويب على نطاق كامل (Full-Stack) وتصميم تطبيقات الجوال.",
    about_text2 : "بتركيز على إنشاء مواقع الويب الرائعة وتطبيقات الهواتف المحمولة الديناميكية، لدي ثلاث سنوات من الخبرة المثبتة في مجال تطوير الويب الكامل وتصميم تطبيقات الهواتف المحمولة.",
    about_text3 : "أنا متحمس للإطلاع على أفكار مشروعك ومناقشة كيف يمكنني مساعدتك في تحقيق أهدافك. سواء كان ذلك تجديد موقع الويب، تطبيق جوال جديد، أو أي شيء آخر، دعونا نتعاون معًا لتحقيق رؤيتك وإحياء مشروعك.",
    skills : "المهارات الرئيسية",
    skill_fullStack : "تطوير الويب الكامل",
    skill_fullStack_text1 : "أنا متخصص في إيجاد حلول الويب المتكاملة (Full-Stack) من الفكرة والتصميم إلى التنفيذ والتطبيق.",
    skill_fullStack_text2 : "خبرتي تمتد عبر تقنيات الواجهة الأمامية والخلفية، مما يتيح لي إنشاء مواقع سلسة وسهلة الاستخدام.",
    skill_mobile : "تطوير تطبيقات الهواتف",
    skill_mobile_text : "بالإضافة إلى تطوير الويب، أنا أُجيد إنشاء تطبيقات الهواتف محمولة لمنصتي iOS و Android. سواء كان التطبيق أصليًا أم متعدد المنصات، لدي المهارات اللازمة لتحويل فكرة تطبيقك في الهاتف المحمول إلى واقع.",
    skill_responsive : "التصميم المتجاوب",
    skill_responsive_text : "من أولوياتي الرئيسية ضمان أن المواقع والتطبيقات تظهر وتعمل بشكل جميل على جميع الأجهزة. أستخدم مبادئ التصميم المتجاوب لضمان تجربة سلسة على الهواتف الذكية والأجهزة اللوحية وأجهزة الكمبيوتر الشخصية.",
    skill_backEnd : "تطوير الويب الخلفي",
    skill_backEnd_text : "لدي خبرة في تقنيات الخادم، وقواعد البيانات، وواجهات البرمجة (APIs). أقوم ببناء أنظمة خلفية قوية وقابلة للتوسيع لدعم وظائف تطبيقات الويب والجوال.",
    skill_ProblemSolving : "حل المشكلات",
    skill_ProblemSolving_text : "عقليتي التحليلية ومهارات حل المشكلات تمكنني من مواجهة التحديات المعقدة بكفاءة وتقديم حلول مبتكرة.",
    // work with me section
    wokr_with_me : "لماذا العمل معي",
    quality_header : "الجودة والدقة",
    quality_text : "أفتخر بتقديم عمل عالي الجودة مع اهتمام دقيق بالتفاصيل. مشروعكم سيتم تنفيذه بإتقان.",
    collaborative_header : "نهج تعاوني",
    collaborative_text : "أؤمن بالتواصل المفتوح والتعاون. مساهمتكم وملاحظاتكم أمور أساسية لنجاح المشروع.",
    onTime_header : "التسليم في الوقت المحدد",
    onTime_text : "أتفهم أهمية التسليم في المواعيد النهائية. أنا ملتزم بتسليم المشاريع في الوقت المحدد وفي حدود ميزانيتكم.",
    learning_header : "التعلم المستمر",
    learning_text : "التكنولوجيا تتطور باستمرار. أنا ملتزم بمتابعة آخر التطورات وأفضل الأساليب لتقديم أفضل الحلول المبتكرة لك.",
    //service section
    my_services : "خدماتي",
    //project section
    project : "مشروعاتي",
    link_project : "رابط المشروع",
    additionnal : "سيتم عرض مشاريع إضافية على الموقع في المستقبل",
    //hire section
    hire : "مشاريع طويلة الأجل",
    hire_text_1 : "هذا القسم مخصص لأشخاص الذين يهتمون بالمشاريع طويلة الأمد أو متوسطة الأمد، أنا متحمس لسماع أفكار مشروعك",
    hire_text_2 : "ونناقش كيف يمكنني مساعدتك في تحقيق أهدافك، لا تتردد في التواصل معي عبر معلومات الاتصال أدناه.",
    forntEnd : "الواجهة الأمامية والتصميم",
    backEnd : "الواجهة الخلفية وقاعدة البيانات",
    languages : "اللغات المُتحدث بها",
    languages_arab : "العربية",
    languages_french : "الفرنسية",
    languages_english : "الإنجليزية",
    languages_level : "معرفة جيدة",
    languages_native : "اللغة الأم",
    myName : "أيمن اشنايف",
    fullStack : "مهندس تكنولوجيا كاملة (Full-stack)",
    //contact section
    contact : "تواصل معي",
    fullName : "الاسم الكامل",
    fullName_placeholder : "اسمكم الكامل",
    subject : "الموضوع",
    message : "الرسالة",
    file : "وثيقة متطلبات الموقع الإلكتروني",
    file_text : "إذا كنت قد أعددتم متطلبات موقع الويب الخاص بكم، يرجى مشاركتها معي. سأرسل لكم عرض الأسعار في أقرب وقت",
    send_message : "إرسال الرسالة",
    //footer section
    phone : "الهاتف",
    useful_links : "روابط مفيدة",
    terms : "شروط الخدمة",
    privacy : "سياسة الخصوصية",
    landing : "صفحات الهبوط (Landing pages)",
    web : "تطبيقات الويب",
    mobile : "تطبيقات الهواتف المحمولة",
    maintenance : "صيانة المواقع الإلكترونية",
    plugin : "إضافات ووردبريس (Plugins)",
    copyright : "حقوق النشر",
    rights : "جميع الحقوق محفوظة",
}